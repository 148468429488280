<template>
  <div class="wrapper">
    <div class="main-title">
      <img src="../assets/halloffame.png" class="main-title-img" />
    </div>
    <div class="main-contents">
      <div
        class="main-item-container"
        v-for="(item, index) in items"
        :key="index"
      >
        <div class="main-items">
          <div class="item-photo">
            <img :src="'img/' + item.Photo" class="item-photo-img" />
          </div>
          <div class="item-name">
            <div class="item-name-text">{{ item.Name }}</div>
          </div>
          <div class="item-category">
            <strong>{{ item.Category }}</strong>
          </div>
          <div class="item-description">{{ item.Description }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      items: [
        {
          Name: "Captain Hook",
          Category: "Eternal staff chief",
          Description:
            "Our eternal POC staff chief. With his gentle charisma, all staffs have made POC the best conference ever.",
          Photo: "CaptainHook.png"
        },
        {
          Name: "Dailysecu",
          Category: "Best Press",
          Description:
            "Since the beginning of POC, Dailysecu has connected people with new hacking & security techniques through its excellent articles.",
          Photo: "Dailysecu.png"
        },
        {
          Name: "GilGil",
          Category: "Best Multiman",
          Description:
            "Presented for 4 consecutive years. He has been with POC as an event organizer and staff.",
          Photo: "GilGil.png"
        },
        {
          Name: "Liang Chen",
          Category: "Best Speaker",
          Description:
            "Presented for 5 consecutive years. He has demonstrated new iOS jailbreaks and has been decorating the finale of POC recently.",
          Photo: "LiangChen.png"
        },
        {
          Name: "MJ0011",
          Category: "Best Speaker",
          Description:
            "Presented for 7 consecutive years. Especially, he embarrassed Microsoft by showing new techniques to bypass Windows's new security.",
          Photo: "MJ0011.png"
        },
        {
          Name: "Passket",
          Category: "Best Support Speaker",
          Description:
            "At the very moment a speaker missed his talk, Passket showed up all of sudden and gave wonderful talks. He is a best backup speaker and insane drinker.",
          Photo: "Passket.png"
        },
        {
          Name: "POXX Team",
          Category: "Best Event Team",
          Description:
            "Thanks to these folks, we could have POXX hacking contest.They are best event organizers.",
          Photo: "poxxteam.png"
        },
        {
          Name: "Xpl017Elz",
          Category: "Best Speaker",
          Description:
            "He has shown outstanding performance in Android exploitation. As a security researcher and company representative, he is topnotch.",
          Photo: "Xpl017Elz.png"
        },
        {
          Name: "Xu Hao",
          Category: "Best Speaker",
          Description:
            "Presented for 9 consecutive years. Especially, he inspired a lot of researchers with iOS exploitation techniques.",
          Photo: "HaoXu.png"
        },
        {
          Name: "Zeronights",
          Category: "Best Supporting Friends",
          Description:
            "Best security conference in Russia. POC and Zeronights have been cooperating to promote the development of security community.",
          Photo: "Zeronights.png"
        },
        {
          Name: "박진억",
          Category: "Best Supporter",
          Description:
            "As a hotel manager, he gave us generous help to run POC smoothly. Thanks.",
          Photo: "anomynous.png"
        }
      ]
    };
  }
};
</script>

<style>
body {
  font-family: "Quicksand", sans-serif;
  color: #000;
  background-color: #070707; /* Used if the image is unavailable */
  background-image: url("../assets/bg.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
}
.wrapper {
  text-align: center;
}
.main-title {
  color: #fff;
  font-size: 10vh;
  text-align: center;
  margin: 10px 0 10px 0;
}
.main-title-img {
  min-width: 200px;
  width: auto;
}

.main-contents {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.main-item-container {
  background-image: url("../assets/frame.png");
  background-size: 100% 100%;
  width: 300px;
  height: 480px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 3vw 3vw 3vw 3vw;
  text-align: center;
}
.main-item-container:hover {
  background-image: url("../assets/frame_black.png");
  background-size: 100% 100%;
  box-shadow: 0 20px 20px 0 rgba(244, 253, 117, 0.37);
  transform: scale(1.2);
}
.main-items {
  max-width: 250px;
  height: 400px;
  flex-basis: 300px;
  justify-content: center;
  text-align: center;
  margin: 10px 3px 5px 3px;
}
.item-name {
  width: 100%;

  background: url("../assets/spot.png");
  background-size: 100% 100%;
}
.item-name-text {
  font-family: "Lobster", cursive;
  font-size: 35px;
  background: #55ffaa;
  background: -webkit-linear-gradient(left, #fff, #e7c502);
  background: -moz-linear-gradient(right, #fff, #e7c502);
  background: -o-linear-gradient(right, #fff, #e7c502);
  background: linear-gradient(to right, rgb(231, 240, 209), #e7c502);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-align: center;
  font-weight: 700;
  word-break: normal;
  display: inline-block;
  word-wrap: break-word;
  height: 35px;
  line-height: 35px;
}

.item-category {
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  word-break: normal;
  display: inline-block;
  word-wrap: break-word;
  height: 35px;
  line-height: 35px;
}
.item-description {
  font-size: 0.9rem;
  color: #fff;
  padding: 2px 5px 2px;
  word-break: normal;
  display: inline-block;
  word-wrap: break-word;
  width: 100%;
  height: 90px;
}
.item-photo {
  width: 100%;
  height: 230px;
  flex: auto;
  text-align: center !important;
}

.item-photo-img {
  margin-top: 20px;
  width: 90%;
  border-radius: 120px;
  -moz-border-radius: 120px;
  -khtml-border-radius: 120px;
  -webkit-border-radius: 120px;
}
</style>
